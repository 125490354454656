import React, { useMemo } from "react";

import Slider, { CustomArrowProps } from "react-slick";
import "./style.sass";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import useMediaQuery from "../../hooks/useMediaQuery";

interface IHistoryData {
  historyData: IHistoryDataItem[];
}

export interface IHistoryDataItem {
  id?: string;
  title?: string;
  year?: string;
  subtitle?: string;
  text?: string;
  content?: string;
  subcontent?: string;
  subtext?: string;
  description?: string;
  subdescription?: string;
}

const HistorySlider = ({ historyData }: IHistoryData) => {
  const isMobile = useMediaQuery("(max-width: 750px)");

  let slider: any;

  const SlickArrowLeft = (props: CustomArrowProps) => {
    const { className, currentSlide, onClick } = props;

    return (
      <button
        id="arrow-left"
        onClick={onClick}
        disabled={currentSlide === 0}
        {...props}
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 1C8 0.447716 8.44771 0 9 0C9.55229 0 10 0.447715 10 1V14.4849L15.7778 8.70711C16.1683 8.31658 16.8014 8.31658 17.192 8.70711C17.5825 9.09763 17.5825 9.7308 17.192 10.1213L10.1209 17.1924C10.0659 17.2474 10.0061 17.2946 9.94281 17.3342C9.80529 17.7221 9.4351 18 9 18C8.59053 18 8.23854 17.7539 8.08388 17.4015C7.97287 17.353 7.86888 17.2832 7.77802 17.1924L0.706956 10.1213C0.316431 9.7308 0.316431 9.09763 0.706956 8.70711C1.09748 8.31658 1.73064 8.31658 2.12117 8.70711L8 14.5859V1Z"
            fill="#ff3607"
          />
        </svg>
      </button>
    );
  };

  const SlickArrowRight = (props: CustomArrowProps) => {
    const { currentSlide, slideCount, onClick } = props;

    return (
      <button
        id="arrow-right"
        onClick={onClick}
        disabled={currentSlide === slideCount ?? 0 - 1}
        {...props}
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 1C8 0.447716 8.44771 0 9 0C9.55229 0 10 0.447715 10 1V14.4849L15.7778 8.70711C16.1683 8.31658 16.8014 8.31658 17.192 8.70711C17.5825 9.09763 17.5825 9.7308 17.192 10.1213L10.1209 17.1924C10.0659 17.2474 10.0061 17.2946 9.94281 17.3342C9.80529 17.7221 9.4351 18 9 18C8.59053 18 8.23854 17.7539 8.08388 17.4015C7.97287 17.353 7.86888 17.2832 7.77802 17.1924L0.706956 10.1213C0.316431 9.7308 0.316431 9.09763 0.706956 8.70711C1.09748 8.31658 1.73064 8.31658 2.12117 8.70711L8 14.5859V1Z"
            fill="#ff3607"
          />
        </svg>
      </button>
    );
  };

  const settings = useMemo(
    () => ({
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      // touchThreshold: 1,
      // swipeToSlide: true,
      nextArrow: <SlickArrowRight />,
      prevArrow: <SlickArrowLeft />,
      // beforeChange: (_current: any, next: React.SetStateAction<number>) => {
      //   setSlideIndex(next);
      // },
      responsive: [
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 750,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }),
    [isMobile]
  );

  return (
    <>
      <div className="history-slider">
        <Slider className="headermenu" ref={(c) => (slider = c)} {...settings}>
          {historyData.map((item, index) => (
            <div className="history-slider__container" key={index}>
              <div className="history-slider__year">{item.year}</div>
              <div className="history-slider__wrapper">
                <div className="history-slider__title">{item.title}</div>
                <div className="history-slider__subtitle">{item.subtitle}</div>
                {item.text && item.text.length > 0 && (
                  <div className="history-slider__text">{item.text}</div>
                )}

                {item.content && item.content.length > 0 && (
                  <div className="history-slider__content">{item.content}</div>
                )}

                {item.subcontent && item.subcontent.length > 0 && (
                  <div className="history-slider__subcontent">
                    {item.subcontent}
                  </div>
                )}
                {item.subtext && item.subtext.length > 0 && (
                  <div className="history-slider__subtext">{item.subtext}</div>
                )}
                {item.description && item.description?.length > 0 && (
                  <div className="history-slider__description">
                    {item.description}
                  </div>
                )}
                {item.subdescription && item.subdescription?.length > 0 && (
                  <div className="history-slider__subdescription">
                    {item.subdescription}
                  </div>
                )}
              </div>
            </div>
          ))}
          {!isMobile && <div className="history-slider__container" />}
        </Slider>
      </div>
    </>
  );
};

export default HistorySlider;
